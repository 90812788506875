import React from "react"

import Animate from "../components/animate"
import Layout from "../components/layout"
import Seo from "../components/seo"
import * as styles from "../styles/_pageCareers.module.scss"
import { linkButton, posi, iconExternal } from '../styles/_btn.module.scss'

const CareersPage = () => {

  return (
    <Layout home={false}>
      <header className="content-header">
        <Animate className="fadeIn" triggerOnce={true}>
          <h1 className="title1" style={{ transitionDelay: `1s` }}><span>採用情報</span>Careers</h1>
        </Animate>
      </header>
      <div className={styles.wrapper}>
        <section className={styles.joblist}>
          <Animate className="fadeIn" triggerOnce={true}>
            <h2 className="title3">募集職種</h2>
          </Animate>

          <section className={styles.innnerSection}>
            <Animate className="fadeIn" triggerOnce={true}>
              <h3 className="title4">エンジニア職</h3>
              <ul>
                <li>フロントエンドエンジニア</li>
                <li>ソフトウェアエンジニア</li>
                <li>インフラエンジニア</li>
                <li>セキュリティエンジニア</li>
                <li>クラウドプラットフォームエンジニア</li>
                <li>データベースエンジニア</li>
                <li>データエンジニア</li>
                <li>データアナリスト</li>
                <li>データオペレーションエンジニア</li>
                <li>映像配信技術エンジニア</li>
                <li>ネットワークエンジニア</li>
                <li>スタジオネットワークスペシャリスト</li>
                <li>スタジオ/オフィス インフラリーダー</li>
                <li>システムコンサルタント</li>
                <li>経営管理</li>
              </ul>
            </Animate>
          </section>

          <section className={styles.innnerSection}>
            <Animate className="fadeIn" triggerOnce={true}>
              <h3 className="title4">クリエイター職</h3>
              <ul>
                <li>UIデザイナー</li>
              </ul>
            </Animate>
          </section>

          <section className={styles.innnerSection}>
            <Animate className="fadeIn" triggerOnce={true}>
              <h3 className="title4">ビジネス職</h3>
              <ul>
                <li>セキュリティコンサルタント</li>
                <li>人事</li>
              </ul>
            </Animate>
          </section>

        </section>
        <section className={styles.application}>
          <Animate className="fadeIn" triggerOnce={true}>
            <h2 className="title3">募集要項</h2>
          </Animate>

          <section className={styles.innnerSection}>
            <Animate className="fadeIn" triggerOnce={true}>
              <h3 className="title4">キャリア採用</h3>
              <ul className={styles.btnList}>
                <li>
                  <a
                    className={`${linkButton} ${posi}`}
                    style={{ textAlign: `left`, width: `100%` }}
                    href="https://hrmos.co/pages/cyberagent-group/jobs?category=1714905290551390208"
                    target="_blank"
                    rel="noopener noreferrer">
                    グループIT推進本部
                    <span className={iconExternal}></span>
                  </a>
                </li>
                <li>
                  <a
                    className={`${linkButton} ${posi}`}
                    style={{ textAlign: `left`, width: `100%` }}
                    href="https://hrmos.co/pages/cyberagent-group/jobs?category=1614086825291923456"
                    target="_blank"
                    rel="noopener noreferrer">
                    CIU
                    <span className={iconExternal}></span>
                  </a>
                </li>
                <li>
                  <a
                    className={`${linkButton} ${posi}`}
                    style={{ textAlign: `left`, width: `100%` }}
                    href="https://hrmos.co/pages/cyberagent-group/jobs?category=1616285385420349440"
                    target="_blank"
                    rel="noopener noreferrer">
                    システムセキュリティ推進グループ
                    <span className={iconExternal}></span>
                  </a>
                </li>
              </ul>
            </Animate>
          </section>

          <section className={styles.innnerSection}>
            <Animate className="fadeIn" triggerOnce={true}>
              <h3 className="title4">新卒採用</h3>
              <ul className={styles.btnList}>
                <li>
                  <a
                    className={`${linkButton} ${posi}`}
                    style={{ textAlign: `left`, width: `100%` }}
                    href="https://www.cyberagent.co.jp/careers/students/tech/"
                    target="_blank"
                    rel="noopener noreferrer">
                    新卒採用エンジニアコース
                    <span className={iconExternal}></span>
                  </a>
                </li>
                <li>
                  <a
                    className={`${linkButton} ${posi}`}
                    style={{ textAlign: `left`, width: `100%` }}
                    href="https://www.cyberagent.co.jp/careers/students/designer/"
                    target="_blank"
                    rel="noopener noreferrer">
                    新卒採用クリエイターコース
                    <span className={iconExternal}></span>
                  </a>
                </li>
                <li>
                  <a
                    className={`${linkButton} ${posi}`}
                    style={{ textAlign: `left`, width: `100%` }}
                    href="https://www.cyberagent.co.jp/careers/students/biz/"
                    target="_blank"
                    rel="noopener noreferrer">
                    新卒採用ビジネスコース
                    <span className={iconExternal}></span>
                  </a>
                </li>
              </ul>
            </Animate>
          </section>

        </section>
      </div>
    </Layout>
  )
}

export const Head = () => <Seo title="採用情報" pathname="/careers/" />

export default CareersPage
